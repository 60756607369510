<template>
  <div class="pucontainer">
    <!-- 支付页面 -->
    <div class="back">
      <!-- <span class="iconfont icon-jtback" @click="$router.go(-1)">返回订单</span> -->
    </div>
    <template v-if="payShow">
      <div class="title">
        <p>{{ $t('cart.s_pay_price') }}</p>
        <span class="blod">{{ currency }}{{ $route.query.price }}</span>
      </div>
      <div class="choose">
        <label for="radio1" class="citem">
          <img src="@/assets/pay-icon1.png" />
          <q-radio v-model="radio" val="alipay" />
        </label>
        <label for="radio2" class="citem">
          <img src="@/assets/pay-icon2.png" />
          <q-radio v-model="radio" val="wechat" />
        </label>
        <label for="radio3" class="citem">
          <img src="@/assets/yunshanfu.png" />
          <q-radio v-model="radio" val="unionpay" />
        </label>
      </div>
      <div class="btnBox">
        <q-btn size="lg" class="full-width" color="primary" :label="$t('cart.c_play')" :loading="payLoading"
          @click="payOrder()" />
      </div>
    </template>
    <template v-else>
      <div class="title">
        <p>{{ $t('cart.s_pay_price') }}</p>
        <span class="blod">{{ currency }}{{ payInfo.payableamount }}</span>
      </div>
      <div class="des">
        <p>{{ $t('cart.pay_tip') }}</p>
        <p>{{ $t('cart.order_number') }}{{ payInfo.order_no }}</p>
      </div>
      <div class="pay-qrcode">
        <!-- <img src="@/assets/alipay.png" /> -->
        <img v-if="payInfo.qrcode && radio != 'unionpay'" class="qrcode-img" :src="payInfo.qrcode" />
      </div>
    </template>
  </div>
</template>

<script>
import { payApi, payStatus } from '@/api/goods'
import store from '../../store'

export default {
  data() {
    return {
      radio: 'alipay',
      payShow: true,
      payLoading: false,
      payInfo: '',
      timer: null
    }
  },
  computed: {
    currency() {
      return store.state.global.currency
    }
  },
  methods: {
    async payOrder() {
      this.payLoading = true
      if (this.$route.query.isSystem) {
        const res = await payApi({
          type: 1,
          mark: this.$route.query.mark,
          aguid: this.$route.query.aguid,
          source: 2,
          payplatmethod: this.radio,
        })
        if (res.status === 1) {
          this.getOrderPay(res.oguid)
        } else {
          this.$q.notify(
            this.$i18n.locale === 'en' ? res.contenten : res.content
          )
        }
      } else {
        const res = await payApi({
          type: 1,
          mark: this.$route.query.mark,
          aguid: this.$route.query.aguid,
          payplatmethod: this.radio,
          couponguid: this.$route.query.couponguid,
          couponguid2: this.$route.query.couponguid2,
          couponguid3: this.$route.query.couponguid3,
          channel: this.$i18n.locale === 'en' ? 2 : 1,
          urls: window.location.origin + '/cart',
          ...(this.$route.query.balancep && { balancep: this.$route.query.balancep })
        })
        if (res.status === 1) {
          if (res.type == 1) {
            this.$router.push({
              path: '/PaymentInfo',
              query: {
                oguid: res.oguid
              }
            })
          } else {
            this.getOrderPay(res.oguid)
          }
        } else if (res.status === -1) {
          this.$router.push("/cart");
        } else {
          this.$q.notify(
            this.$i18n.locale === 'en' ? res.contenten : res.content
          )
        }
      }
    },
    // 获取订单信息
    async getOrderPay(order) {
      const res = await payApi({
        type: 7,
        mark: order
      })
      if (res.status === 1) {
        this.$router.push({
          query: {
            oguid: res.oguid,
          }
        })
        if (res.payplatmethod === 'unionpay') {
          window.open(res.result)
        }
        this.payInfo = res
        this.radio = res.payplatmethod
        this.payShow = false
        // 订单信息清空
        this.$store.commit('setChichekInfo', '')
        setTimeout(() => {
          // 定时更新订单状态
          this.timer = setInterval(this.electron, 2000)
        }, 1000)
      }
      this.payLoading = false
    },
    // 更新订单状态
    async electron() {
      store.commit('setIsStopLoading') // 停止加载loading动画
      const res = await payStatus({
        type: 1,
        mark: this.payInfo.oguid
      })
      if (res.status === 1) {
        // 支付成功跳转支付成功页
        this.$router.push({
          path: '/PaymentInfo',
          query: {
            oguid: this.payInfo.oguid
          }
        })
      } else if (res.status === -1) {
        // 订单超时返回购物车
        this.$router.push('/cart')
      }
    }
  },
  mounted() {
    if (this.$route.query.oguid) {
      this.getOrderPay(this.$route.query.oguid)
    }
  },
  unmounted() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.pucontainer {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;

  .back {
    width: 100%;
    text-align: left;
    font-size: 16px;

    span {
      margin: 0 5px;
    }
  }

  .title {
    margin-top: 60px;
    font-size: 30px;

    p {
      font-size: 22px;
      vertical-align: middle;
      margin-bottom: 0px;
    }
  }

  .desc {
    margin-top: 80px;
    margin-bottom: 20px;

    p {
      color: #999999;
      text-align: center;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .choose {
    max-width: 970px;
    margin: 100px auto;

    .citem {
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #a8a8a8;

      img {
        width: 166px;
      }
    }
  }

  .btnBox {
    width: 300px;
    margin: 60px auto;
  }

  .pay-qrcode {
    margin: 50px auto;
    position: relative;

    .qrcode-img {
      width: 168px;
      height: 168px;
    }
  }
}
</style>
